<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>OJT/MA Information</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="4">
                                <b-form-group label-for="search-activity-type" label="Training Type:">
                                    <b-input-group size="sm">
                                        <b-select id="search-activity-type" v-model="selectedActivityType" :options="activityTypeOptions">
                                            <template v-slot:first>
                                                <option :value="null"> - </option>
                                            </template>
                                        </b-select>
                                        <b-input-group-addon>
                                            <b-button @click="selectedActivityType = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button class="mr-2" variant="success" @click="getCsv">Export</b-button>
                            <b-button variant="info" @click="clearFilters">Clear</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import _ from 'underscore';
import {errorModalOptions} from "../../../util/formatters";


@Component
export default class OjtMaExport extends Vue {

    selectedActivityType = null;


    get activityTypeOptions() {
        return [{value:"OJT",text:"OJT"},{value:"MA",text:"MA"}]
    }

    clearFilters() {
        this.selectedActivityType = null;
    }

    async getCsv() {
        try {
            await download('POST', '/api/v1/export/ojt-ma', JSON.stringify({
                activityType: this.selectedActivityType
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('OJT/MA Export', null, true));
    }
}
</script>
